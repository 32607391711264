import { Fragment, useCallback, useEffect, useMemo } from "react";
import { getTasks } from "../../api/auth";
import Divider from "../../components/common/divider";
import PageContentWrapper from "../../components/common/pageContentWrapper";
import PageWrapper from "../../components/common/pageWrapper";
import Typography from "../../components/common/typography";
import List from "../../components/tasks/list";
import TaskProgressItem from "../../components/tasks/taskProgressItem";
import TaskReferralItem from "../../components/tasks/taskReferralItem";
import TaskSubscribeItem from "../../components/tasks/taskSubscribeItem/TaskSubscribeItem";
import TaskWatchItem from "../../components/tasks/taskWatchItem";
import { handleErrors } from "../../core/helpers";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setUserTasks } from "../../store/reducers/accountSlice";
import { IAsset, IProcessTask, IProgressTaskItem, IReferralTaskItem, ISubscribeTaskItem, ITaskItem, IUserTask, IWatchTaskItem } from "../../types/types";

interface normalizedTask {
  items: ITaskItem[]
  completed: number
}

enum TaskStatus {
  Completed = 'Completed',
  RewardClaimed = 'RewardClaimed'
}

const getInitialData = (): { [key: string]: normalizedTask } => ({
  'subscribe': {
    items: [], completed: 0,
  },
  'watch': {
    items: [], completed: 0,
  },
  'referral': {
    items: [], completed: 0,
  },
  'progress': {
    items: [], completed: 0,
  }
});

const Tasks = () => {
  const tasks = useAppSelector(state => state.settings.settings.tasks);
  const userTasks = useAppSelector(state => state.account.userTasks);
  const referralsAmount = useAppSelector(state => state.account.referals.total);
  const assetName = useAppSelector(state => state.settings.assetNames.Score);
  const scoreAsset = useAppSelector(state => state.assets.assets.find((item) => item.name === assetName));

  const dispatch = useAppDispatch();

  const getTaskCompletedText = useCallback((completed: number, length: number) => {
    return `Completed (${completed}/${length})`;
  }, []);

  const userTasksMap = useMemo(() => {
    return new Map(userTasks.map((item) => [item.taskId, item]));
  }, [userTasks]);

  const normalizedTasks = useMemo<ITaskItem[]>(() => {
    const taskItems = tasks.map((item) => ({
      ...userTasksMap.get(item.id) || {} as IUserTask,
      ...item,
    }));
    return taskItems;
  }, [userTasksMap, tasks]);

  const splittedTasks = useMemo(() => {
    return normalizedTasks.reduce((a, c) => {
      switch (c.type) {
        case 'Subscribe':
          a.subscribe.items.push(c as ISubscribeTaskItem);
          break;
        case 'Watch':
          a.watch.items.push(c as IWatchTaskItem);
          break;
        case 'Referral':
          a.referral.items.push(c as IReferralTaskItem);
          break;
        case 'Progress':
          a.progress.items.push(c as IProgressTaskItem);
          break;
      }
      if (c.status === TaskStatus.Completed || c.status === TaskStatus.RewardClaimed) {
        const type = c.type.toLowerCase();
        a[type].completed += 1;
      }
      return a;
    }, getInitialData());
  }, [normalizedTasks]);

  const handleProcessTasks = useCallback((task: IProcessTask) => {
    const updatedUserTasks = userTasks.map((item) => item.taskId === task.taskId ? task : item);
    dispatch(setUserTasks(updatedUserTasks));
  }, [dispatch, userTasks]);

  const setup = useCallback(async () => {
    try {
      const userTasks = await getTasks();
      dispatch(setUserTasks(userTasks));
    } catch (err: any) {
      handleErrors(err);
    }
  }, [dispatch]);

  useEffect(() => {
    setup();
  }, [setup]);

  return (
    <PageWrapper>
      <PageContentWrapper>
        <>
          <Typography style={{ textAlign: 'center' }} variant='title2' color='white'>Complete the tasks to earn more rewards</Typography>
          {!!splittedTasks.subscribe.items.length &&
            <List
              title="Task list: Subscribe"
              secondTitle={getTaskCompletedText(splittedTasks.subscribe.completed, splittedTasks.subscribe.items.length)}
              items={splittedTasks.subscribe.items as ISubscribeTaskItem[]}
              renderItem={(task: ISubscribeTaskItem, index: number) =>
                <Fragment key={index}>
                  <TaskSubscribeItem task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.subscribe.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.watch.items.length &&
            <List
              title="Task list: Watch"
              secondTitle={getTaskCompletedText(splittedTasks.watch.completed, splittedTasks.watch.items.length)}
              items={splittedTasks.watch.items as IWatchTaskItem[]}
              renderItem={(task: IWatchTaskItem, index: number) =>
                <Fragment key={index}>
                  <TaskWatchItem task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.watch.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.referral.items.length &&
            <List
              title="Task list: Invite"
              secondTitle={getTaskCompletedText(splittedTasks.referral.completed, splittedTasks.referral.items.length)}
              items={splittedTasks.referral.items as IReferralTaskItem[]}
              renderItem={(task: IReferralTaskItem, index: number) =>
                <Fragment key={index}>
                  <TaskReferralItem referralsAmount={referralsAmount} task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.referral.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
          {!!splittedTasks.progress.items.length &&
            <List
              title="Task list: Progress"
              secondTitle={getTaskCompletedText(splittedTasks.progress.completed, splittedTasks.progress.items.length)}
              items={splittedTasks.progress.items as IProgressTaskItem[]}
              renderItem={(task: IProgressTaskItem, index: number) =>
                <Fragment key={index}>
                  <TaskProgressItem key={task.id} asset={scoreAsset as IAsset} task={task} onClick={handleProcessTasks} />
                  {index !== splittedTasks.progress.items.length - 1 && <Divider />}
                </Fragment>}
            />
          }
        </>
      </PageContentWrapper>
    </PageWrapper>
  );
}

export default Tasks;
