import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IAsset } from '../../types/types'
import { getAssets } from '../../api/wallet'
import { handleErrors } from '../../core/helpers'

export const fetchAssets = createAsyncThunk<IAsset[], undefined, { rejectValue: string }>(
  'assets/fetchAssets',
  async (_, { rejectWithValue }) => {
    try {
      return await getAssets();
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
)
interface AssetsState {
  assets: IAsset[]
}

const initialState: AssetsState = {
  assets: [],
}

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAssets.fulfilled, (state, action) => {
      state.assets = action.payload;
    })
  }
})

export default assetsSlice.reducer;