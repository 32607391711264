import { NavLink } from 'react-router-dom';

import { ReactComponent as WalletIcon } from '../../assets/footer/WalletIcon.svg';
import { ReactComponent as TreeIcon } from '../../assets/footer/TreeIcon.svg';
import { ReactComponent as FriendsIcon } from '../../assets/footer/FriendsIcon.svg';
import { ReactComponent as TasksIcon } from '../../assets/footer/TasksIcon.svg';
import { ReactComponent as RankIcon } from '../../assets/footer/RankIcon.svg';

import styles from './Footer.module.scss';

const routes = [
  {
    title: 'Wallet',
    to: '/wallet',
    icon: WalletIcon,
  },
  {
    title: 'Tree',
    to: '/',
    icon: TreeIcon,
  },
  {
    title: 'Friends',
    to: '/friends',
    icon: FriendsIcon,
  },
  {
    title: 'Tasks',
    to: '/tasks',
    icon: TasksIcon,
  },
  {
    title: 'Ranking',
    to: '/statistics',
    icon: RankIcon,
  },
];

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.controls}>
        {routes.map((item) =>
          <NavLink key={item.title} to={item.to}>
            {({ isActive }) => (
              <div className={styles.control__wrapper} >
                <item.icon stroke={`${isActive ? 'var(--basic--primary)' : 'var(--basic--secondary)'}`} />
                <span style={{ color: `${isActive ? 'var(--basic--primary)' : 'var(--basic--secondary)'}` }}>{item.title}</span>
              </div>
            )}
          </NavLink>
        )}
      </div>
    </div>
  );
}

export default Footer;
