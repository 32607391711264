import { QRCodeSVG } from 'qrcode.react';
import Dialog from '../../common/dialog';

import styles from './DialogShareQr.module.scss';

interface IProps {
  handleClose: () => void,
  address: string,
}

const DialogShareQr = ({ address, handleClose }: IProps) => {
  return (
    <Dialog
      onClose={handleClose}
      title='Share you address'
    >
      <div className={styles.content__wrapper}>
        <div className={styles.qr__wrapper}>
          <QRCodeSVG value={address} size={256} style={{ width: '100%' }} />
        </div>
      </div>
    </Dialog>
  );
}

export default DialogShareQr;
