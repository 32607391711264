import { Fragment, useEffect, useMemo } from 'react';
import FriendItem from "../../components/friends/friendItem";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import CopyButton from "../../components/common/copyButton";
import Typography from '../../components/common/typography';
import RewardInfoItem from '../../components/friends/rewardInfoItem';

import styles from './Friends.module.scss';
import List from '../../components/tasks/list';
import Divider from '../../components/common/divider';
import Button from '../../components/common/button';

import { ReactComponent as ShareIcon } from '../../assets/friends/ShareIcon.svg';
import { ReactComponent as DropIcon } from '../../assets/friends/DropIcon.svg';
import { ReactComponent as GiftIcon } from '../../assets/friends/GiftIcon.svg';
import RewardButton from '../../components/friends/rewardButton';
import { fetchReferrals } from '../../store/reducers/accountSlice';
import { IReferal } from '../../types/types';
import PageWrapper from '../../components/common/pageWrapper';
import PageContentWrapper from '../../components/common/pageContentWrapper';

const tg = window.Telegram.WebApp;

const iniviteText = 'Grow your tree and save the planet.';
const shareUrl = 'https://t.me/share/url';

const Friends = () => {
  const friends = useAppSelector(state => state.account.referals.data);
  const friendsAmount = useAppSelector(state => state.account.referals.total);
  const replenishTime = useAppSelector(state => state.settings.settings.replenish.repeat) / 3600;
  const assets = useAppSelector(state => state.settings.settings.replenish.assets);
  const miniAppUrl = useAppSelector(state => state.settings.settings.social.miniAppUrl);

  const dispatch = useAppDispatch();

  const scoreAsset = useMemo(() => {
    return assets.find((item) => item.type === 'Score');
  }, [assets]);

  const balanceAsset = useMemo(() => {
    return assets.find((item) => item.type === 'Balance');
  }, [assets]);

  const rewardItems = useMemo(() => [
    {
      title: 'Send the invitation link',
      description: `Once your friends log in to Humidify, you will receive bonuses for each friend.`,
      icon: ShareIcon,
    },
    {
      title: 'Your friends water their trees',
      description: `Every time your friends water their trees, you will earn extra ${((balanceAsset?.refFactor || 0) * 100)} ${balanceAsset?.name}. You will also earn extra ${((scoreAsset?.refFactor || 0) * 100)}% ${scoreAsset?.name} for each friend when you water your own tree.`,
      icon: DropIcon,
    },
    {
      title: 'Claim your extra rewards',
      description: `All rewards from friends will be totaled and can be claimed once every ${replenishTime.toFixed(2)} hours.`,
      icon: GiftIcon,
    },
  ], [balanceAsset?.refFactor, scoreAsset?.refFactor, balanceAsset?.name, scoreAsset?.name, replenishTime]);

  useEffect(() => {
    dispatch(fetchReferrals());
  }, [dispatch]);

  return (
    <PageWrapper>
      <PageContentWrapper>
        <>
          <Typography style={{ textAlign: 'center' }} variant='title2' color='white'>Invite your friends to earn more rewards</Typography>
          <Typography variant='subheadline2' color='white'>Get extra rewards from your friends</Typography>

          <RewardButton />

          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <Typography style={{ marginLeft: '16px' }} variant='subheadline1' color='white'>How it works</Typography>
            {rewardItems.map((item, index) => <RewardInfoItem key={item.title} title={item.title} description={item.description} icon={<item.icon />} order={index + 1} />)}
          </div>

          <List
            items={friends}
            title={`Your friends (${friendsAmount})`}
            emptyListMessage='Your friend list is empty.'
            renderItem={(item: IReferal, index: number) =>
              <Fragment key={item.id}>
                <FriendItem friend={item} />
                {index !== friends.length - 1 && <Divider />}
              </Fragment>}
          />

          <div className={styles.buttons__container}>
            <a style={{ width: '100%', textDecoration: 'none' }} href={`${shareUrl}?url=${miniAppUrl}?startapp=ref${tg.initDataUnsafe.user.id}&text=${encodeURIComponent(iniviteText)}`}>
              <Button>Invite a friend</Button>
            </a>
            <CopyButton data={`${miniAppUrl}?startapp=ref${tg.initDataUnsafe.user.id}`} />
          </div>
        </>
      </PageContentWrapper>
    </PageWrapper>
  );
}

export default Friends;
