import { useCallback } from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as CopyIcon } from '../../../assets/common/CopyIcon.svg';
import IconButton from '../iconButton';

interface IProps {
  data: any,
  sx?: any,
  message?: string,
}

const CopyButton = ({data, sx, message}: IProps) => {
  const copy = useCallback(() => {
    navigator.clipboard.writeText(data);
    toast.success(message || 'Copied successfully!');
  },[data, message]);
  return (
    <IconButton onClick={copy}>
      <CopyIcon />
    </IconButton>
  );
}

export default CopyButton;
