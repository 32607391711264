import { useCallback } from "react";
import { useRouteError } from "react-router-dom";
import Typography from "../../components/common/typography";

import { ReactComponent as ErrorTreeIcon } from '../../assets/error/ErrorTreeIcon.svg';

import styles from './ErrorPage.module.scss';
import Button from "../../components/common/button";

export default function ErrorPage() {
  const error: any = useRouteError();

  const handleClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div id="error-page" className={styles.wrapper}>
      <ErrorTreeIcon />
      <div className={styles.info__wrapper}>
        <Typography variant="subheadline1" color="primary">Oooooh!</Typography>
        <Typography variant="title2" color="white" style={{ textAlign: 'center' }}>
          Our masters are working
          in the digital garden.
        </Typography>
        <Typography variant="subheadline2" color="secondary" style={{ display: 'inline-block', wordBreak: 'break-word' }}>{error.message}</Typography>
        <Button onClick={handleClick}>reload the page</Button>
      </div>
    </div>
  );
}