import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset, { getFormattedAmount } from '../../../hooks/useFormattedAsset';
import { IAsset, IProcessTask, IProgressTaskItem } from '../../../types/types';
import TaskItem from '../taskItem';
import { useAppSelector } from '../../../store/hooks';

import { ReactComponent as GrowIcon } from '../../../assets/tasks/GrowIcon.svg';
import { ReactComponent as DropIcon } from '../../../assets/friends/DropIcon.svg';

interface IProps {
  task: IProgressTaskItem,
  asset: IAsset,
  onClick: (task: IProcessTask) => void,
}

const TaskProgressItem = ({ task, asset, onClick }: IProps) => {
  const account = useAppSelector(state => state.account.account);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const leftIcon = useMemo(() => {
    if (task.settings.measure === 'Score') {
      return <GrowIcon />
    }
    return <DropIcon />
  }, [task.settings.measure]);

  const completeText = useMemo(() => {
    if (task.settings.measure === 'Score') {
      return `${getFormattedAmount(asset, account.score)} / ${getFormattedAmount(asset, task.settings.value)}`
    }
    return `${account.replenishCount} / ${task.settings.value}`;
  }, [account.replenishCount, account.score, task.settings.measure, task.settings.value, asset]);

  const handleClick = useCallback(async () => {
    try {
      const res = await processTask(task.id.toString());
      toast(`You've got ${formattedAsset.amount} ${formattedAsset.name}`);
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    }
  }, [formattedAsset.amount, formattedAsset.name, onClick, task.id]);

  return (
    <TaskItem type={task.type} title={task.title} status={task.status} completeText={completeText} leftIcon={leftIcon} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskProgressItem;
