import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { processTask } from '../../../api/auth';
import { handleErrors } from '../../../core/helpers';
import useFormattedAsset from '../../../hooks/useFormattedAsset';
import { IProcessTask, IReferralTaskItem } from '../../../types/types';

import { ReactComponent as UserIcon } from '../../../assets/tasks/UserIcon.svg';

import TaskItem from '../taskItem';

interface IProps {
  task: IReferralTaskItem,
  referralsAmount: number,
  onClick: (task: IProcessTask) => void,
}

const TaskReferralItem = ({ task, referralsAmount, onClick }: IProps) => {
  const completeText = useMemo(() => {
    return `${referralsAmount} / ${task.settings.count}`;
  }, [referralsAmount, task.settings.count]);
  const formattedAsset = useFormattedAsset(null, task.reward);
  const handleClick = useCallback(async () => {
    try {
      const res = await processTask(task.id.toString());
      toast(`You've got ${formattedAsset.amount} ${formattedAsset.name}`);
      onClick(res);
    } catch (err: any) {
      handleErrors(err);
    }
  }, [formattedAsset.amount, formattedAsset.name, onClick, task.id]);

  return (
    <TaskItem type={task.type} title={task.title} status={task.status} completeText={completeText} leftIcon={<UserIcon />} description={`+ ${formattedAsset.amount} ${formattedAsset.name}`} onClick={handleClick} />
  );
}

export default TaskReferralItem;
