import React, { useEffect } from 'react';
import { useAppSelector } from '../../../store/hooks';
import DialogDailyRewards from '../../dialogDailyRewards';

interface IProps {
  children: React.ReactElement,
  style?: object,
}

const PageWrapper = ({ children, style }: IProps) => {
  const account = useAppSelector(state => state.account.account);
  return (
    <div className='page-wrapper' style={style}>
      {account.hasDailyReward && <DialogDailyRewards />}
      {children}
    </div>
  );
}

export default PageWrapper;
