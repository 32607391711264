import { Outlet } from 'react-router-dom';

import styles from './Main.module.scss';
import Footer from '../../components/footer';

const Main = () => {
  return (
    <div className={styles.main}>
      <Outlet />
      <Footer />
    </div>
  );
}

export default Main;
