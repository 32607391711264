import { IAddressBalance, IAsset, ISendTransaction, INetworkSettings, ITransaction } from "../types/types";

const postHeaders = {
  'Content-Type': 'application/json'
};

let apiUrl = '';

export const setHubUrl = (url: string) => {
  apiUrl = url;
}
export const getHubUrl = () => {
  return apiUrl;
}

export async function fetchData(url: string, config: any = {}): Promise<any> {
  config.headers = config.headers || {};
  config.headers["Content-Type"] = "application/json";

  try {
    const res = await fetch(url, config);
    if (res.ok) {
      return res;
    }
    const error = await res.json();
    throw { errors: error.errors, statusCode: res.status };
  } catch (err: any) {
    throw err;
  }
}

export const getBalance = async (address: string, assetId?: string): Promise<IAddressBalance> => {
  const url = `${getHubUrl()}/accounts/${address}/balance${assetId ? `/${assetId}` : ''}`;
  const res = await fetchData(url);
  return await res.json();
}

export const getTransactions = async (address: string): Promise<{ data: ITransaction[], total: number }> => {
  const url = `${getHubUrl()}/accounts/${address}/transactions?take=5&assetId=base`;
  const res = await fetchData(url);
  return await res.json();
}

export const getSettings = async (): Promise<INetworkSettings> => {
  const url = `${getHubUrl()}/settings`;
  const res = await fetchData(url);
  return await res.json();
}

export const getAssets = async (): Promise<IAsset[]> => {
  const url = `${getHubUrl()}/assets`;
  const res = await fetchData(url);
  return await res.json();
}

export const sendTransaction = async (data: ISendTransaction) => {
  const url = `${getHubUrl()}/transactions`;
  await fetchData(url, { method: 'POST', headers: postHeaders, body: JSON.stringify(data) });
};