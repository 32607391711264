import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { RankType } from '../../../types/types';
import Typography from '../../common/typography';

import styles from './StatisticsItem.module.scss';

import { ReactComponent as StatisticsScoreIcon } from '../../../assets/statistics/StatisticsScoreIcon.svg';
import { ReactComponent as StatisticsWaterIcon } from '../../../assets/statistics/StatisticsWaterIcon.svg';
import { ReactComponent as StatisticsFriendIcon } from '../../../assets/statistics/StatisticsFriendIcon.svg';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

interface IProps {
  type: RankType;
  isActive: boolean;
  onSwipe: (type: RankType) => void;
}

const StatisticsItem = ({ type, isActive, onSwipe }: IProps) => {
  const statistics = useAppSelector(state => state.statistics.statistics);
  const itemData = useMemo(() => {
    switch (type) {
      case RankType.Score:
        return {
          icon: <StatisticsScoreIcon style={{ width: 30, height: 30 }} />,
          score: statistics.overallScore,
        };
      case RankType.Referrals:
        return {
          icon: <StatisticsFriendIcon style={{ width: 30, height: 30 }} />,
          score: statistics.totalReferrals,
        };
      case RankType.ReplenishCount:
        return {
          icon: <StatisticsWaterIcon style={{ width: 30, height: 30 }} />,
          score: statistics.totalReplenishCount,
        };
    }
  }, [type, statistics.overallScore, statistics.totalReferrals, statistics.totalReplenishCount]);
  useEffect(() => {
    if (!isActive) {
      return;
    }
    onSwipe(type);
  }, [isActive, onSwipe, type]);
  return (
    <div className={`${isActive ? `${styles.container} ${styles.container_active}` : styles.container}`}>
      <div className={styles.data_container}>
        {itemData.icon}
        <Typography variant='title-large' color='white'>{formatter.format(itemData.score)}</Typography>
      </div>
    </div>
  );
}

export default StatisticsItem;
