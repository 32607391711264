import React from 'react';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';

import styles from './RewardInfoItem.module.scss';

interface IProps {
  title: string,
  description: string,
  icon: React.ReactNode,
  order: number,
}

const RewardInfoItem = ({ title, description, icon, order }: IProps) => {
  return (
    <div className={styles.reward_info__container}>
      <div className={styles.reward_info__wrapper}>
        <LeftSideIcon icon={icon} subIconContent={order}/>
        <div className={styles.reward_info}>
          <Typography variant='body1' color='white'>{title}</Typography>
          <Typography variant='subheadline2' color='secondary'>{description}</Typography>
        </div>
      </div>
    </div>
  );
}

export default RewardInfoItem;
