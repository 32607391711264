import React from 'react';
import Typography from '../../common/typography';

import styles from './List.module.scss';

interface IProps<T> {
  items: T[],
  renderItem: (item: T, index: number) => React.ReactNode,
  title?: string,
  secondTitle?: string,
  emptyListMessage?: string
}

function List<T>(props: IProps<T>) {
  return (
    <div className={styles.list__wrapper}>
      <div className={styles.title__container}>
        {props.title && <Typography style={{ marginLeft: '16px' }} variant='subheadline1' color='white'>{props.title}</Typography>}
        {props.secondTitle && <Typography style={{ marginLeft: '16px' }} variant='subheadline2' color='secondary'>{props.secondTitle}</Typography>}
      </div>
      {!!props.items.length ? <div className={styles.list}>
        {props.items.map((item, index) => props.renderItem(item, index))}
      </div>
        :
        <Typography style={{ marginLeft: '16px' }} variant='caption1' color='secondary'>{props.emptyListMessage}</Typography>}
    </div>
  );
}

export default List;



