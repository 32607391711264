import { useMemo } from "react";
import { useAppSelector } from "../store/hooks";
import { IAsset } from "../types/types";

export const getFormattedAmount = (asset: IAsset | null | undefined, amount: number, defaultDecimals?: number) => {
  const decimals = (asset?.decimals === null || asset?.decimals === undefined) 
    ? (defaultDecimals || 0) 
    : asset?.decimals;
  if(!decimals) {
    return amount;
  }
  const str = amount.toString();
  return `${str.slice(0, Math.max(str.length - decimals, 0)) || '0'}.${str.slice(-decimals).toString().padStart(decimals, '0')}`;
}

const getAssetName = (asset: IAsset | null | undefined, baseAssetName: string) => {
  return asset?.name || baseAssetName;
}

const useFormattedAsset = (id: string | null, amount: number) => {
  const defaultDecimals = useAppSelector(state => state.settings.settings.networkSettings.decimals);
  const baseAssetName = useAppSelector(state => state.settings.assetNames.Balance);
  const assets = useAppSelector(state => state.assets.assets);

  return useMemo(() => {
    const asset = assets.find((item) => item.id === id);
    return { name: getAssetName(asset, baseAssetName), amount: getFormattedAmount(asset, amount, defaultDecimals) };
  }, [id, assets, amount, defaultDecimals, baseAssetName]);
}

export default useFormattedAsset;