import React from 'react';

import styles from './TreeLoader.module.scss';
import Typography from '../../common/typography';
import AnimatedGif from '../../common/animatedGif';

import WateringCan from '../../../assets/WateringCan.gif';

const TreeLoader = () => {
  return (
    <div className={styles.wrapper}>
      <AnimatedGif src={WateringCan} alt='gif' style={{ minWidth: '150px', minHeight: '150px' }} />
      <Typography variant='subheadline1' color='primary'>Just a moment, please!</Typography>
      <Typography variant='title2' color='white'>We're taking care of your tree</Typography>
    </div>
  );
}

export default TreeLoader;
