import MainLoader from '../../common/loaders/mainLoader';
import SocialsButton from '../../common/socialsButton';
import Typography from '../../common/typography';

import styles from './GlobalLoader.module.scss';

import { ReactComponent as YoutubeSocialsIcon } from '../../../assets/socials/YoutubeSocialsIcon.svg';
import { ReactComponent as TelegramSocialsIcon } from '../../../assets/socials/TelegramSocialsIcon.svg';
import { ReactComponent as TwitterSocialsIcon } from '../../../assets/socials/TwitterSocialsIcon.svg';

const GlobalLoader = () => {
  return (
    <div className={styles.loader__wrapper}>
      <div className={styles.loader__block__wrapper}>
        <div className={styles.loader__block}>
          <MainLoader />
          <Typography variant='subheadline1' color='black'>LOADING</Typography>
        </div>
        <div className={styles.loader__block}>
          <Typography variant='title-large' color='black'>Grow green</Typography>
          <Typography variant='title-large' color='black'>Earn HMDF</Typography>
        </div>
      </div>
      <div className={styles.loader_bottom_block__wrapper}>
        <Typography variant='title3' color='primary'>Join our community</Typography>
        <div className={styles.loader_socials}>
        {/* <SocialsButton size={44}>
            <TwitterSocialsIcon />
          </SocialsButton> */}
          <SocialsButton size={44}>
            <TelegramSocialsIcon />
          </SocialsButton>
          {/* <SocialsButton size={44}>
            <YoutubeSocialsIcon />
          </SocialsButton> */}
        </div>
      </div>
    </div>
  );
}

export default GlobalLoader;
