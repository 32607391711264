import { ReactComponent as WaterIcon } from '../../../assets/WaterIcon.svg';

import { useCallback, useMemo, useState } from 'react';
import useFormattedAsset, { getFormattedAmount } from '../../../hooks/useFormattedAsset';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

import styles from './TreeHeader.module.scss';
import Typography from '../../common/typography';
import { replenish } from '../../../api/auth';
import { toast } from 'react-toastify';
import { fetchAccount } from '../../../store/reducers/accountSlice';
import { handleErrors } from '../../../core/helpers';

import { ReactComponent as TreeScoreIcon } from '../../../assets/friends/TreeScoreIcon.svg';
import { useReplenishTimer } from '../../../hooks/useReplenishTimer';

enum AssetType {
  Balance = 'Balance',
  Score = 'Score',
}

const TreeHeader = () => {
  const lastReplenish = useAppSelector(state => state.account.account.lastReplenish);
  const repeatReplenish = useAppSelector(state => state.settings.settings.replenish.repeat) * 1000;
  const defaultDecimals = useAppSelector(state => state.settings.settings.networkSettings.decimals);
  const score = useAppSelector(state => state.account.account.score);
  const replenishTimer = useReplenishTimer(lastReplenish, repeatReplenish);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const assets = useAppSelector(state => state.settings.settings.replenish.assets);

  const scoreAsset = useMemo(() => assets.find((item) => item.type === AssetType.Score), [assets]);
  const balanceAsset = useMemo(() => assets.find((item) => item.type === AssetType.Balance), [assets]);

  const formattedScoreAsset = useFormattedAsset(scoreAsset?.assetId || null, score);

  const dispatch = useAppDispatch();

  const formattedAsset = useFormattedAsset(null, balanceAsset?.amount || 0);

  const handleReplenish = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await replenish();
      const balanceAsset = res.assets.find((item) => item.type === AssetType.Balance);
      const formattedAmount = getFormattedAmount(null, balanceAsset?.amount || 0, defaultDecimals);
      toast(`You've got ${formattedAmount} ${formattedAsset.name}!`);
      dispatch(fetchAccount());
    } catch (err: any) {
      handleErrors(err);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, formattedAsset.name, defaultDecimals]);

  return (
    <div className={styles.container__wrapper}>
      <div className={styles.center_info_container}>
        <Typography variant='title2' color='white'>Water your tree to earn</Typography>
        <Typography variant='title1' color='white'>{formattedAsset.amount} {formattedAsset.name}</Typography>
      </div>
      <div className={styles.side_info_container__left}>
        <Typography variant='caption1' color='secondary'>Remaining time:</Typography>
        <Typography variant='body1' color='white'>{replenishTimer || '00:00:00'}</Typography>
      </div>
      <div className={styles.container_box_fill}></div>
      <div
        onClick={handleReplenish}
        className={styles.replenish_button_container}
        style={replenishTimer || isLoading ? { pointerEvents: 'none' } : {}}
      >
        {!replenishTimer && !isLoading && <WaterIcon style={{ marginLeft: '1.25px' }} />}
      </div>
      <div className={styles.side_info_container__right}>
        <Typography variant='caption1' color='secondary'>Overall Growth:</Typography>
        <div className={styles.tree_score__wrapper}>
          <TreeScoreIcon />
          <Typography variant='body1' color='white'>{formattedScoreAsset.amount}</Typography>
        </div>
      </div>
    </div>
  );
}

export default TreeHeader;
