import { useCallback, useEffect } from 'react';
import { getToken } from '../../api/auth';
import { handleErrors } from '../../core/helpers';
import storage from '../../core/storage';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchAccount, fetchReferrals, fetchUserTasks, setCredentials } from '../reducers/accountSlice';
import { fetchAssets } from '../reducers/assetsSlice';
import { fetchSettings } from '../reducers/settingsSlice';
import { fetchBalance, fetchTransactions } from '../reducers/walletSlice';
import { getWallet, isCredentialsEncrypted } from '../../core/wallet';
import { fetchRanks, fetchStatistics } from '../reducers/statisticsSlice';
import { RankType } from '../../types/types';

const tg = window.Telegram.WebApp;

export interface SetupStoreProps {
  onSetup: () => void;
  onError?: (err: any) => void;
}

export default function SetupStore({ onSetup, onError }: SetupStoreProps) {
  const address = useAppSelector(state => state.account.account.walletAddress);
  const dispatch = useAppDispatch();

  const setup = useCallback(async () => {
    try {
      await dispatch(fetchSettings());
      dispatch(fetchAssets());
      const credentials = await storage.getItem(process.env.REACT_APP_CLOUD_STORAGE_KEY as string);
      if (!credentials) {
        const wallet = getWallet();
        const phrase = wallet.getMnemonicPhrase();
        dispatch(setCredentials(phrase));
        await storage.setItem(process.env.REACT_APP_CLOUD_STORAGE_KEY as string, phrase);
        await getToken({ grantType: 'Signature', payload: tg.initData, walletAddress: wallet.getAddress() });
      } else {
        let walletAddress;
        if (!isCredentialsEncrypted(credentials)) {
          const wallet = getWallet(credentials);
          walletAddress = wallet.getAddress();
        }
        await getToken({ grantType: 'Signature', payload: tg.initData, walletAddress });
        dispatch(setCredentials(credentials));
      }
      await dispatch(fetchAccount());
    } catch (err: any) {
      handleErrors(err);
      onError && onError(err);
    } finally {
      onSetup();
    }
  }, [dispatch, onError, onSetup]);

  const setupAccount = useCallback(async () => {
    if (!address) {
      return;
    }
    dispatch(fetchBalance(address));
    dispatch(fetchTransactions(address));
    dispatch(fetchReferrals());
    dispatch(fetchUserTasks());
    dispatch(fetchRanks(RankType.Score));
    dispatch(fetchStatistics());
  }, [dispatch, address]);

  useEffect(() => {
    setup();
    setupAccount();
  }, [setup, setupAccount]);

  return null;
}