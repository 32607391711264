
import { IReferal } from '../../../types/types';
import { useAppSelector } from '../../../store/hooks';
import Typography from '../../common/typography';

import styles from './FriendItem.module.scss';

import { ReactComponent as TreeScoreIcon } from '../../../assets/friends/TreeScoreIcon.svg';
import { useMemo } from 'react';
import useFormattedAsset from '../../../hooks/useFormattedAsset';

interface FriendItemProps {
  friend: IReferal,
}

const FriendItem = ({ friend }: FriendItemProps) => {
  const assets = useAppSelector(state => state.settings.settings.replenish.assets);
  const scoreAsset = useMemo(() => {
    return assets.find((item) => item.type === 'Score');
  },[assets]);
  const formattedAsset = useFormattedAsset(scoreAsset?.assetId || null, friend.score);
  return (
    <div className={styles.friend_item__wrapper}>
      <div className={styles.friend_item}>
        <div className='flex-break-word'>
          <Typography variant='body1' color='white'>{friend.username}</Typography>
        </div>
        <div className={styles.friend_score__wrapper}>
          <TreeScoreIcon />
          <Typography variant='subheadline2' color='white'>{formattedAsset.amount}</Typography>
        </div>
      </div>
    </div>
  );
}

export default FriendItem;
