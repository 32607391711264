import { Suspense, useEffect } from 'react';
import DialogDailyRewards from '../../components/dialogDailyRewards';
import TreeLoader from '../../components/loaders/treeLoader';

import TreeHeader from '../../components/tree/treeHeader';
import { lazyWithDelay } from '../../core/helpers';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchAccount } from '../../store/reducers/accountSlice';

const Tree = lazyWithDelay(() => import('./Tree'), 2000);

const TreePage = () => {
  const account = useAppSelector(state => state.account.account);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAccount());
  }, [dispatch]);

  return (
    <div id='tree' style={{ position: 'relative' }}>
      {account.hasDailyReward && <DialogDailyRewards />}
      <TreeHeader />
      <Suspense fallback={<TreeLoader />}>
        <Tree />
      </Suspense>
    </div>
  );
}

export default TreePage;

