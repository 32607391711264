import { useCallback, useEffect, useState } from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import GlobalLoader from './components/loaders/globalLoader';
import StoreProvider from './store/components/StoreProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Friends from './pages/friends';
import Tasks from './pages/tasks';
import Wallet from './pages/wallet';
import Main from './pages/main';
import ErrorPage from './pages/error-page';
import TreePage from './pages/tree';
import Statistics from './pages/statistics';

const tg = window.Telegram?.WebApp;
tg.expand();
tg.disableVerticalSwipes();
tg.ready();

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <TreePage />,
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/friends",
        element: <Friends />,
      },
      {
        path: "/wallet",
        element: <Wallet />,
      },
      {
        path: "/statistics",
        element: <Statistics />,
      },
    ]
  },
]);

function App() {
  const [isLoading, setIsLoading] = useState(true);

  const handleSetup = useCallback(() =>
    setTimeout(() => {
      setIsLoading(false);
    }, 1000),
    []);

  useEffect(() => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader!.style.display = 'none';
    }
  }, []);

  return (
    <StoreProvider onSetup={handleSetup}>
      <ToastContainer theme="dark" />
      {
        !isLoading ? <RouterProvider router={router} /> : <GlobalLoader />
      }
    </StoreProvider>
  );
}

export default App;
