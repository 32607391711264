import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IApplicationSettings } from '../../types/types'
import { handleErrors } from '../../core/helpers'
import { getAuthSettings } from '../../api/auth'
import { setHubUrl } from '../../api/wallet'

export const fetchSettings = createAsyncThunk<IApplicationSettings, undefined, { rejectValue: string }>(
  'settings/fetchSettings',
  async (_, { rejectWithValue }) => {
    try {
      return await getAuthSettings();
    } catch (err: any) {
      handleErrors(err);
      return rejectWithValue(err.message);
    }
  }
)

interface IAssetNames {
  'Balance': string,
  'Score': string,
}

interface SettingsState {
  settings: IApplicationSettings,
  assetNames: IAssetNames,
  explorerUrl: string,
}

const initialState: SettingsState = {
  settings: {} as IApplicationSettings,
  assetNames: {} as IAssetNames,
  explorerUrl: '',
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSettings.fulfilled, (state, action) => {
      const assetNames = action.payload.replenish.assets.reduce((a, c) => {
        a[c.type] = c.name;
        return a;
      }, {} as any);
      state.settings = action.payload;
      state.assetNames = assetNames;
      state.explorerUrl = action.payload.networkSettings.explorerUrl;
      setHubUrl(action.payload.networkSettings.hubUrl);
    })
  }
})

export default settingsSlice.reducer;