import React, { useMemo } from 'react';
import IconButton from '../../common/iconButton';
import LeftSideIcon from '../../common/leftSideIcon';
import Typography from '../../common/typography';

import { ReactComponent as CompleteIcon } from '../../../assets/tasks/CompleteIcon.svg';
import { ReactComponent as CoinsIcon } from '../../../assets/tasks/CoinsIcon.svg';
import { ReactComponent as CoinsDisabledIcon } from '../../../assets/tasks/CoinsDisabledIcon.svg';
import { ReactComponent as RocketIcon } from '../../../assets/tasks/RocketIcon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/tasks/CheckIcon.svg';
import { ReactComponent as TrophyIcon } from '../../../assets/tasks/TrophyIcon.svg';

import styles from './TaskItem.module.scss';

interface IProps {
  type: string,
  title: string,
  description: string,
  leftIcon: React.ReactNode,
  status: string,
  completeText?: string,
  onClick: () => void,
}

const TaskItem = ({ type, title, description, leftIcon, status, completeText, onClick }: IProps) => {
  const rightIcon = useMemo(() => {
    if(type === 'Referral' || type === 'Progress') {
      switch (status) {
        case 'Completed':
          return <CoinsIcon />;
        case 'RewardClaimed':
          return null;
        default:
          return <CoinsDisabledIcon />
      }
    }
    switch (status) {
      case 'NotStarted':
        return <RocketIcon />;
      case 'Started':
        return <CheckIcon />;
      case 'Completed':
        return <CoinsIcon />;
      default:
        return null;
    }
  },[status, type]);

  const iconDisabled = useMemo(() => {
    return (type === 'Referral' || type === 'Progress') && status !== 'Completed';
  },[type, status]);

  return (
    <div className={styles.task_item__wrapper}>
      <div className={styles.task_item}>
        <div style={{ maxWidth: '56px' }}>
          <LeftSideIcon icon={leftIcon} subIconContent={status === 'RewardClaimed' ? <CompleteIcon /> : null}/>
        </div>
        <div className={`${styles.task_info__wrapper} flex-break-word`}>
          <Typography variant='body1' color='white'>{title}</Typography>
          <Typography variant='subheadline2' color='white'>{description}</Typography>
          {completeText && <div className={styles.task_complete__wrapper}>
            <TrophyIcon />
            <Typography variant='caption1' color='secondary'>{completeText}</Typography>
          </div>}
        </div>
        {rightIcon && <div style={{ maxWidth: '56px' }}>
          <IconButton disabled={iconDisabled} onClick={onClick}>{rightIcon}</IconButton>
        </div>}
      </div>
    </div>
  );
}

export default TaskItem;
