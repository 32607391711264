import { useCallback, useEffect, useState } from "react";

export const useReplenishTimer = (lastReplenish: string, repeatReplenish: number) => {
  const [replenishTimer, setReplenishTimer] = useState<string | null>(null);

  const updateTimer = useCallback(() => {
    if (!repeatReplenish || !lastReplenish) {
      setReplenishTimer(null);
      return;
    }
    const now = new Date().getTime();
    const nextReplenishTime = new Date(lastReplenish).getTime() + repeatReplenish;
    if (now > nextReplenishTime) {
      setReplenishTimer(null);
      return;
    }
    const distance = nextReplenishTime - now;
    if (distance < 0) {
      setReplenishTimer(null);
      return;
    }
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    const timer = `0${hours}:${minutes && minutes < 10 ? `0${minutes}` : minutes || '00'}:${seconds < 10 ? `0${seconds}` : seconds}`;
    setReplenishTimer(timer);

    setTimeout(() => updateTimer(), 1000);
  }, [lastReplenish, repeatReplenish]);

  useEffect(() => {
    updateTimer();
  }, [updateTimer]);

  return replenishTimer;
}