import React from 'react';

import styles from './MainLoader.module.scss';

import { ReactComponent as Loader } from '../../../../assets/loaders/MainLoader.svg';

const MainLoader = ({ size = 85 }: { size?: number }) => {
  return (
    <div className={styles.loader__wrapper} style={{ width: size, height: size }}>
      <Loader style={{ width: size, height: size }}/>
    </div>
  );
}

export default MainLoader;
